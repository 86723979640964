import Layout from "../components/Layout";

export default function ContactPage() {
  return (
    <Layout>
      <form action="https://formspree.io/f/mayvbnqv" method="POST" className="max-w-lg">
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
              First Name
              <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" type="text" name="first_name" />
            </label>
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
              Last Name
              <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" type="text" name="last_name" />
            </label>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
              Email
              <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" type="email" name="email" />
            </label>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
              Subject
              <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="subject" />
            </label>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
              Message
              <textarea className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="message" />
            </label>
          </div>
        </div>

        <div className="flex flex-row-reverse">
          <button type="submit" className="bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4">Send</button>
        </div>
      </form>
    </Layout>
  );
}
